import * as React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet";

import style from "./markdown.module.scss";

interface Page {
	html: string
	frontmatter: {
		title: string
	}
}

export default function Template(data: any) {
	const page = data.data.markdownRemark as Page
	return (
		<>
			<Helmet>
				<title>{page.frontmatter.title}</title>
			</Helmet>
			<div className={style.page} dangerouslySetInnerHTML={{ __html: page.html }} />
		</>
	)
}

export const pageQuery = graphql`
	query BlogPostByPath($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
			}
		}
	}
`
